
import {
  AnalyticFilter,
  AnalyticsState,
  AnalyticsTypes,
} from "@/store/modules/analytics/analytics.types";
import VueApexCharts from "vue-apexcharts";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const analyticX = namespace("Analytics");

@Component({
  components: {
    StatsAvatar: () => import("@/components/dashboard/StatsAvatar.vue"),
    VueApexCharts,
  },
})
export default class TicketsByCategories extends Vue {
  @analyticX.State(AnalyticsTypes.CATEGORY_ANALYTICS)
  public productAnalytics!: AnalyticsState;

  @analyticX.Action(AnalyticsTypes.LOAD_CATEGORY_ANALYTICS)
  public loadProductStats!: (filter: AnalyticFilter) => void;

  get series(): unknown {
    return this.productAnalytics.analytics.map((a) => a.count);
  }

  get options(): unknown {
    return {
      legend: {
        position: "bottom",
      },
      colors: [
        "#F07A44",
        "#F7BB4E",
        "#EE5373",
        "#8B73D7",
        "#6E838E",
        "#51A89C",
        "#6EE6A6",
        "#429EF7",
      ],
      labels: this.productAnalytics.analytics.map((a) => a.name),
      chart: {
        toolbar: {
          show: false,
        },
      },
    };
  }

  @Prop({ default: undefined }) public start?: string;
  @Prop({ default: undefined }) public end?: string;

  filterChanged(value: { forced?: boolean }): void {
    this.loadProductStats({
      start: this.start,
      end: this.end,
      force: value.forced,
    });
  }

  mounted(): void {
    this.filterChanged({});
  }
}
